$(document).ready(function() {
  // открытие мобильного меню
  var $mobileLeftNavController = $('.js_mobileLeftNavController');
  var $mobileLeftNavContainer = $('.js_mobileLeftNavContainer');

  $mobileLeftNavController.on('click', function(event) {
    $(this).toggleClass('mobileLeftNavController_opened');
    $mobileLeftNavContainer.toggleClass('mobileLeftNavContainer_opened');
  })

  // Дополнительная навигация
  var $subMenuWithAnchor = $('.list-group-item.active+.js_subMenuWithAnchor');
  var $links = $subMenuWithAnchor.find('a');
  $links.on('click', function(event) {
    event.preventDefault();
    var linkId = $(this).attr('href');
    var targetContent = $(linkId);
    var body = $("html, body");
    body.stop().animate({
      scrollTop: targetContent.offset().top - 140
    }, 700, function() {
      location.hash = linkId;
    })
    $mobileLeftNavContainer.removeClass('mobileLeftNavContainer_opened');
    $mobileLeftNavController.removeClass('mobileLeftNavController_opened');
  })
  // Наблюдение за скроллом
  $('body').scrollspy({
    target: '.js_subMenuWithAnchor',
    offset: 140
  });
  // Закрепления меню для десктопов если экран достаточно большой

  if ($subMenuWithAnchor.length > 0) {
    subMenuDesktopFollow($subMenuWithAnchor);
  }
});

function subMenuDesktopFollow($subMenu) {
  var startFixingPos = $subMenu.offset().top;
  var $menuParrent = $subMenu.closest('.js_followContainer');
  var startMenuPos = $menuParrent.offset().top;
  var fixed = true;
  $(window).scroll(function(event) {
    // console.log($(window).height());
    // console.log($subMenu.height());
    if ($subMenu.height() < $(window).height() - 200 && $(window).width() > 990) {
      if (startFixingPos < event.currentTarget.scrollY && fixed) {
        $subMenu.addClass("followMe");
        $subMenu.next('.list-group-item').css('margin-top', $subMenu.height() + 25 + 'px');
      } else {
        $subMenu.removeClass("followMe");
        $subMenu.next('.list-group-item').css('margin-top', '');
      }
    }
  })
  $('.js_pinSubMenu').on('click', function() {
    if (fixed) {
      fixed = false;
      $subMenu.removeClass("followMe");
      $subMenu.next('.list-group-item').css('margin-top', '');
    } else {
      fixed = true;
    }
  })

  $(window).resize(function() {
    startFixingPos = $subMenu.prev().offset().top + $subMenu.prev().height();
    if ($(window).width() < 990) {
      fixed = false;
      $subMenu.next('.list-group-item').css('margin-top', '');
    } else if ($subMenu.hasClass('followMe')) {
      $subMenu.next('.list-group-item').css('margin-top', $subMenu.height() + 25 + 'px');
      fixed = true;
    }
  })
}

// $(window).load(function(){
//
//     if(document.getElementById("carousel-slide-template") && $('.js_carouselInner').length>0){
//         var source   = document.getElementById("carousel-slide-template").innerHTML;
//         var template = Handlebars.compile(source);
//         var $js_carouselInner = $('.js_carouselInner');
//         var div = document.createElement('div');
//         var globalCoursesCarouselDataArray_cash = JSON.parse(JSON.stringify(globalCoursesCarouselDataArray));
//         for (var i = 0; i < globalCoursesCarouselDataArray.length; i++) {
//
//             var random = Math.floor(Math.random()*(globalCoursesCarouselDataArray_cash.length));
//             if(i==0){
//                 globalCoursesCarouselDataArray_cash[random].active = "active"
//             }
//             //console.log(globalCoursesCarouselDataArray_cash[random])
//             var html = template(globalCoursesCarouselDataArray_cash[random]);
//             globalCoursesCarouselDataArray_cash.splice(random, 1);
//             $(div).append(html);
//         }
//         var htmlend = template(globalCoursesCarouselDataLast);
//         $(div).append(htmlend);
//         $js_carouselInner.html($(div).html());
//     }
//
//   // листалка каруселей на широком экране
//   var slide = document.querySelector('.carousel-slide');
//   var position = 0;
//   var count = 0;
//
//   if (window.innerWidth < 500) {
//     $('body').on('click', '.fa-angle-right', swiftRight);
//     $('body').on('click', '.fa-angle-left', swiftLeft);
//   } else {
//     if (document.querySelector('.carouselInner') && document.querySelector('.carouselInner').clientWidth < document.querySelector('.carouselOuter').clientWidth) {
//       document.querySelectorAll('.controls')[0].classList.add('transparent');
//       document.querySelectorAll('.controls')[1].classList.add('transparent');
//     };
//     if (slide && slide.clientWidth*count == 0) {
//       document.querySelectorAll('.controls')[0].classList.add('transparent');
//     };
//     $('body').on('click', '.fa-angle-right', moveRight);
//     $('body').on('click', '.fa-angle-left', moveLeft);
//   }
//
//   function moveRight() {
//     if (slide.clientWidth*count < ( document.querySelector('.carouselInner').clientWidth - document.querySelector('.carouselOuter').clientWidth) ) {
//       ++count;
//       document.querySelectorAll('.controls')[1].classList.remove('transparent');
//       document.querySelectorAll('.controls')[0].classList.remove('transparent');
//     }
//     if (slide.clientWidth*count > ( document.querySelector('.carouselInner').clientWidth - document.querySelector('.carouselOuter').clientWidth) ) {
//       document.querySelectorAll('.controls')[1].classList.add('transparent');
//     }
//     position = Math.min((slide.clientWidth+10)*count, document.querySelector('.carouselInner').clientWidth - document.querySelector('.carouselOuter').clientWidth);
//     if ( document.querySelector('.carouselOuter').clientWidth >= document.querySelector('.carouselInner').clientWidth ) {
//       position = Math.min(slide.clientWidth*count, 0);
//     }
//     $('.carouselInner').css('transform', 'translateX(-' + position + 'px)');
//     $('.carouselInner').css('transition', 'transform 0.3s ease-out');
//   };
//   function moveLeft() {
//     if (slide.clientWidth*count > 0) {
//       --count;
//       document.querySelectorAll('.controls')[1].classList.remove('transparent');
//       document.querySelectorAll('.controls')[0].classList.remove('transparent');
//     }
//     if (slide.clientWidth*count == 0)  {
//       document.querySelector('.controls:first-of-type').classList.add('transparent');
//     }
//     position = Math.min((slide.clientWidth+10)*count, document.querySelector('.carouselInner').clientWidth - document.querySelector('.carouselOuter').clientWidth);
//
//     if ( document.querySelector('.carouselOuter').clientWidth >= document.querySelector('.carouselInner').clientWidth ) {
//       position = Math.min(slide.clientWidth*count, 0);
//     }
//     $('.carouselInner').css('transform', 'translateX(-' + position + 'px)');
//     $('.carouselInner').css('transition', 'transform 0.3s ease-out');
//   };
//   function swiftRight() {
//     if (!document.querySelector('.carousel-slide.active')) {
//       document.querySelector('.carousel-slide').classList.add('active');
//     }
//     var active = document.querySelector('.carousel-slide.active');
//     if (active.nextElementSibling) {
//       active.nextElementSibling.classList.add('active');
//     } else {
//       document.querySelector('.carousel-slide').classList.add('active');
//     }
//     active.style.animation = "swiftRight 0.5s";
//     active.classList.remove('active');
//     document.querySelector('.carousel-slide.active').style.animation = "swiftRight 0.5s";
//   }
//
//   function swiftLeft() {
//     if (!document.querySelector('.carousel-slide.active')) {
//       document.querySelector('.carousel-slide').classList.add('active');
//     }
//     var active = document.querySelector('.carousel-slide.active');
//     if (active.previousElementSibling) {
//       active.previousElementSibling.classList.add('active');
//     } else {
//       document.querySelectorAll('.carousel-slide')[document.querySelectorAll('.carousel-slide').length-1].classList.add('active');
//     }
//     active.style.animation = "swiftLeft 0.5s";
//     active.classList.remove('active');
//     document.querySelector('.carousel-slide.active').style.animation = "swiftLeft 0.5s";
//   }
//
//   // swipe-листалка каруселей на мобильном экране
//   var touchstartX = 0;
//   var touchendX = 0;
//
//   var carousel = document.querySelector(".carouselOuter") || document.querySelector("#myCarousel");
//
//   carousel.ontouchstart = function(event) {
//     touchstartX = event.touches[0].clientX;
//   };
//
//   carousel.ontouchend = function(event) {
//     touchendX = event.changedTouches[0].clientX;
//     if (touchendX < touchstartX) {
//       if ($('.fa-angle-right')) {
//         $('.fa-angle-right').trigger('click');
//       }
//       if ($('.carousel-control.right')) {
//         $('.carousel-control.right').trigger('click');
//       }
//     }
//     if (touchendX > touchstartX)  {
//       if ($('.fa-angle-left')) {
//         $('.fa-angle-left').trigger('click');
//       }
//       if ($('.carousel-control.left')) {
//         $('.carousel-control.left').trigger('click');
//       }
//     }
//   }
//
//   $(window).resize(function(){
//     if (window.innerWidth < 500) {
//       document.querySelectorAll('.controls').forEach(function(ctrl) {
//           ctrl.classList.remove('transparent');
//       });
//       $('body').on('click', '.fa-angle-right', swiftRight);
//       $('body').on('click', '.fa-angle-left', swiftLeft);
//     }
//   })
// })
